import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import User = App.Models.User;

import * as auth from '$common/apis/auth.ts';
import { useRbacStore } from '$common/modules/auth/stores/rbac.ts';

export const useAuthStore = defineStore('Auth', () => {
  const route = useRoute();
  const router = useRouter();
  const rbacStore = useRbacStore();

  const user = ref<User | null>(window.user ?? null);
  const isLoggedIn = computed(() => user.value !== null);
  const currentUser = computed(() => user.value);

  /**
   * Clears the user and token from state.
   */
  function clear() {
    user.value = null;
  }

  /**
   * Attempt to log in with given credentials and redirect to requested route if any.
   */
  async function login(email: string, password: string) {
    // setup session
    await auth.login(email, password);

    // load user into state
    await me();
    rbacStore.refresh();

    if (route?.query?.from) {
      const { from: path, ...query } = route.query;

      void router.replace({
        // @ts-expect-error type mismatch
        path,
        query,
      });
    } else {
      void router.replace('/');
    }
  }

  /**
   * Clears the state and send the user to redirect route.
   */
  async function logout() {
    clear();
    rbacStore.clear();
    window.location.pathname = '/logout';
  }

  /**
   * Loads current user into state.
   */
  async function me() {
    try {
      user.value = await auth.me();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // 401 error is already handled by http interceptor
      if (error?.response?.status !== 401) {
        void logout();
      }
    }
  }

  return {
    isLoggedIn,
    currentUser,
    login,
    me,
    logout,
    setAvatar(url: string) {
      if (user.value) {
        user.value.avatar_url = url;
      }
    },
    setName(name: string) {
      if (user.value) {
        user.value.name = name;
      }
    },
    setEmail(email: string) {
      if (user.value) {
        user.value.email = email;
      }
    },
  };
});
