import { Guest } from '$common/modules/auth/middelwares.ts';

export const routes = [
  {
    path: '/login',
    meta: {
      middlewares: [Guest],
      title: 'Login',
    },
    name: 'Login',
    component: () => import('./views/Login.vue'),
  },
  {
    path: '/forgotten-password',
    meta: {
      middlewares: [Guest],
      title: 'Reset Password',
    },
    name: 'ForgottenPassword',
    component: () => import('./views/ForgottenPassword.vue'),
  },
  {
    path: '/password/reset/:token',
    meta: {
      middlewares: [Guest],
      title: 'Reset Password',
    },
    name: 'ResetForgottenPassword',
    component: () => import('./views/ResetForgottenPassword.vue'),
  },
];
